
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EDrawer',
  props: {
    /**
     * @zh 标题
     * @en title
     * @values String
     * @defaultValue -
     */
    title: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  emits: ['confirm', 'cancel', 'close', 'open'],
  setup(_, { emit }) {
    const confirm = () => {
      emit('confirm');
    };

    const cancel = () => {
      emit('cancel');
    };

    const close = () => {
      emit('close');
    };

    const open = () => {
      emit('open');
    };

    return {
      confirm,
      cancel,
      close,
      open
    };
  }
});
